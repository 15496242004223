.score--box {
    display: flex;
    /* flex-direction: row; */
    
}

.score--box > h1 {
    font-family: 'Carter One', sans-serif;
    color: white;
    margin: 1em;
    letter-spacing: .1em;
    text-shadow: 2px 2px 1px #0066a2, -2px 2px 1px #0066a2, 2px -2px 1px #0066a2, -2px -2px 1px #0066a2, 0px 2px 1px #0066a2, 0px -2px 1px #0066a2, 0px 4px 1px #004a87, 2px 4px 1px #004a87, -2px 4px 1px #004a87;
}
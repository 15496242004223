.keyboard {
    margin-top: 1em;
    width: 100%;
    padding: 20px 0;
    /* background: #004134; */
    background-color: #020144;
    /* box-shadow: 0 0 50px rgba(0, 0, 0, 0.1); */
    user-select: none;
    transition: bottom 0.4s;
}

.keyboard--hidden {
    bottom: -100%;
}

.keyboard__keys {
    text-align: center;
}

.keyboard__key {
    /* height: 45px; */
    height: 5em;
    width: 6%;
    max-width: 90px;
    margin: 3px;
    border-radius: 4px;
    border: none;
    background: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    font-size: 1.05rem;
    outline: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
    position: relative;
}

@media (max-width: 768px) {
    .keyboard__key {
        width: 2rem;
        height: 2rem;
    }
}

.keyboard__key:active {
    background: rgba(255, 255, 255, 0.12);
}

.keyboard__key--wide {
    width: 12%;
}

.keyboard__key--extra-wide {
    width: 36%;
    max-width: 500px;
}

.keyboard__key--activatable::after {
    content: '';
    top: 10px;
    right: 10px;
    position: absolute;
    width: 8px;
    height: 8px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
}

.keyboard__key--active::after {
    background: #08ff00;
}

.keyboard__key--dark {
    background: rgba(0, 0, 0, 0.25);
}

.keyboard__key--z {
    margin-left: 1em;
}

.keyboard__key--m {
    margin-right: 1em;
}

.keyboard__row {
    margin-left: 1em;
    margin-right: 1em;
}

.keyboard__key--press {
    height: 60px;
}
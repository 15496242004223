@import url(https://fonts.googleapis.com/css?family=Carter+One);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  display: flex;
  height: 100vh;
  width: 100vw;
  /* justify-content: flex-end; */
  justify-content: center;
  flex-direction: column;
  background-color: #020144;
  align-items: center;
  font-family: 'Carter One', sans-serif;
  color: white;
  letter-spacing: .1em;
  text-shadow: 2px 2px 1px #0066a2, -2px 2px 1px #0066a2, 2px -2px 1px #0066a2, -2px -2px 1px #0066a2, 0px 2px 1px #0066a2, 0px -2px 1px #0066a2, 0px 4px 1px #004a87, 2px 4px 1px #004a87, -2px 4px 1px #004a87;
  
}

.keyboard {
    margin-top: 1em;
    width: 100%;
    padding: 20px 0;
    /* background: #004134; */
    background-color: #020144;
    /* box-shadow: 0 0 50px rgba(0, 0, 0, 0.1); */
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    transition: bottom 0.4s;
}

.keyboard--hidden {
    bottom: -100%;
}

.keyboard__keys {
    text-align: center;
}

.keyboard__key {
    /* height: 45px; */
    height: 5em;
    width: 6%;
    max-width: 90px;
    margin: 3px;
    border-radius: 4px;
    border: none;
    background: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    font-size: 1.05rem;
    outline: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
    position: relative;
}

@media (max-width: 768px) {
    .keyboard__key {
        width: 2rem;
        height: 2rem;
    }
}

.keyboard__key:active {
    background: rgba(255, 255, 255, 0.12);
}

.keyboard__key--wide {
    width: 12%;
}

.keyboard__key--extra-wide {
    width: 36%;
    max-width: 500px;
}

.keyboard__key--activatable::after {
    content: '';
    top: 10px;
    right: 10px;
    position: absolute;
    width: 8px;
    height: 8px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
}

.keyboard__key--active::after {
    background: #08ff00;
}

.keyboard__key--dark {
    background: rgba(0, 0, 0, 0.25);
}

.keyboard__key--z {
    margin-left: 1em;
}

.keyboard__key--m {
    margin-right: 1em;
}

.keyboard__row {
    margin-left: 1em;
    margin-right: 1em;
}

.keyboard__key--press {
    height: 60px;
}
.game-button {
    position: relative;
    top: 0;
    cursor: pointer;
    text-decoration: none !important;
    outline: none !important;
    font-family: 'Carter One', sans-serif;
    font-size: 20px;
    line-height: 1.5em;
    letter-spacing: .1em;
    text-shadow: 2px 2px 1px #0066a2, -2px 2px 1px #0066a2, 2px -2px 1px #0066a2, -2px -2px 1px #0066a2, 0px 2px 1px #0066a2, 0px -2px 1px #0066a2, 0px 4px 1px #004a87, 2px 4px 1px #004a87, -2px 4px 1px  #004a87;
    border: none;
    margin: 15px 15px 30px;
    background: repeating-linear-gradient( 45deg, #3ebbf7, #3ebbf7 5px, #45b1f4 5px, #45b1f4 10px);
    border-bottom: 3px solid rgba(16, 91, 146, 0.5);
    border-top: 3px solid rgba(255,255,255,.3);
    color: #fff !important;
    border-radius: 8px;
    padding: 8px 15px 10px;
    box-shadow: 0 6px 0 #266b91, 0 8px 1px 1px rgba(0,0,0,.3), 0 10px 0 5px #12517d, 0 12px 0 5px #1a6b9a, 0 15px 0 5px #0c405e, 0 15px 1px 6px rgba(0,0,0,.3);
  }
  .game-button:hover {
    top:2px;
    box-shadow: 0 4px 0 #266b91, 0 6px 1px 1px rgba(0,0,0,.3), 0 8px 0 5px #12517d, 0 10px 0 5px #1a6b9a, 0 13px 0 5px #0c405e, 0 13px 1px 6px rgba(0,0,0,.3);
  }
  .game-button::before {
    content: '';
    height: 10%;
    position: absolute;
    width: 40%;
    background: #fff;
    right: 13%;
    top: -3%;
    border-radius: 99px;
    }
  .game-button::after {
    content: '';
    height: 10%;
    position: absolute;
    width: 5%;
    background: #fff;
    right: 5%;
    top: -3%;
    border-radius: 99px;
    }
  
    .game-button.orange {
      background: repeating-linear-gradient( 45deg, #ffc800, #ffc800 5px, #ffc200 5px, #ffc200 10px);
     box-shadow: 0 6px 0 #b76113, 0 8px 1px 1px rgba(0,0,0,.3), 0 10px 0 5px #75421f, 0 12px 0 5px #8a542b, 0 15px 0 5px #593116, 0 15px 1px 6px rgba(0,0,0,.3);
     border-bottom: 3px solid rgba(205, 102, 0, 0.5);
     text-shadow: 2px 2px 1px #e78700, -2px 2px 1px #e78700, 2px -2px 1px #e78700, -2px -2px 1px #e78700, 0px 2px 1px #e78700, 0px -2px 1px #e78700, 0px 4px 1px #c96100, 2px 4px 1px #c96100, -2px 4px 1px  #c96100;
   }
   .game-button.orange:hover {
     top:2px;
     box-shadow: 0 4px 0 #b76113, 0 6px 1px 1px rgba(0,0,0,.3), 0 8px 0 5px #75421f, 0 10px 0 5px #8a542b, 0 13px 0 5px #593116, 0 13px 1px 6px rgba(0,0,0,.3);
   }
   .game-button.red {
      background: repeating-linear-gradient( 45deg, #ff4f4c, #ff4f4c 5px, #ff4643 5px, #ff4643 10px);
     box-shadow: 0 6px 0 #ae2725, 0 8px 1px 1px rgba(0,0,0,.3), 0 10px 0 5px #831614, 0 12px 0 5px #a33634, 0 15px 0 5px #631716, 0 15px 1px 6px rgba(0,0,0,.3);
     border-bottom: 3px solid rgba(160, 25, 23, 0.5);
     text-shadow: 2px 2px 1px #d72d21, -2px 2px 1px #d72d21, 2px -2px 1px #d72d21, -2px -2px 1px #d72d21, 0px 2px 1px #d72d21, 0px -2px 1px #d72d21, 0px 4px 1px #930704, 2px 4px 1px #930704, -2px 4px 1px  #930704;
   }
   .game-button.red:hover {
     top:2px;
     box-shadow: 0 4px 0 #ae2725, 0 6px 1px 1px rgba(0,0,0,.3), 0 8px 0 5px #831614, 0 10px 0 5px #a33634, 0 13px 0 5px #631716, 0 13px 1px 6px rgba(0,0,0,.3);
   }
   .game-button.green {
      background: repeating-linear-gradient( 45deg, #54d440, #54d440 5px, #52cc3f 5px, #52cc3f 10px);
       box-shadow: 0 6px 0 #348628, 0 8px 1px 1px rgba(0,0,0,.3), 0 10px 0 5px #2a6d20, 0 12px 0 5px #39822e, 0 15px 0 5px #1d4c16, 0 15px 1px 6px rgba(0,0,0,.3);
       border-bottom: 3px solid rgba(40, 117, 29, 0.5);
       text-shadow: 2px 2px 1px #348628, -2px 2px 1px #348628, 2px -2px 1px #348628, -2px -2px 1px #348628, 0px 2px 1px #348628, 0px -2px 1px #348628, 0px 4px 1px #1d4c16, 2px 4px 1px #1d4c16, -2px 4px 1px #1d4c16;
   }
   .game-button.green:hover {
     top:2px;
     box-shadow: 0 4px 0 #348628, 0 6px 1px 1px rgba(0,0,0,.3), 0 8px 0 5px #2a6d20, 0 10px 0 5px #39822e, 0 13px 0 5px #1d4c16, 0 13px 1px 6px rgba(0,0,0,.3);
   }
.retry-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.save-score-page {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.save-score-input {
  border: none;
  cursor: pointer;
  transition: box-shadow 0.2s;
  text-transform: uppercase;
  padding: 1em;
  width: 600px;
  text-align: center;
  font-size: 2em;
  border-radius: 20px;
  background-color: #020144;
  box-shadow: 0 0px 0px 4px rgba(255, 255, 255, 0.1);
  color: rgba(240, 255, 255, 0.753);
  margin-bottom: 2em;
}

.save-score-input:focus {
  content: "";
  outline: none;
  box-shadow: 0 0px 0px 4px rgba(255, 255, 255, 0.6);
}

.save-score-input--success {
  -webkit-animation-name: success;
          animation-name: success;
  -webkit-animation-duration: 400ms;
          animation-duration: 400ms;
}

.save-score-input--failure {
  -webkit-animation-name: failure;
          animation-name: failure;
  -webkit-animation-duration: 400ms;
          animation-duration: 400ms;
}

.high-scores-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Carter One", sans-serif;
  color: white;
  margin: 1em;
  letter-spacing: 0.1em;
  text-shadow: 2px 2px 1px #0066a2, -2px 2px 1px #0066a2, 2px -2px 1px #0066a2,
    -2px -2px 1px #0066a2, 0px 2px 1px #0066a2, 0px -2px 1px #0066a2,
    0px 4px 1px #004a87, 2px 4px 1px #004a87, -2px 4px 1px #004a87;
}

.high-scores-table {
  text-align: center;
  margin: 1em 0;
  background-color: rgba(218, 165, 32);
  border-radius: 20px;
  padding: 1em;
}

.high-scores-header {
  background: #004a87;
}

.high-scores-row {
  background: #0066a2;
}

.high-scores-column {
  font-size: 1.1em;
  padding: 1rem;
}

@-webkit-keyframes success {
  0% {
    box-shadow: 0 0px 0px 4px rgba(255, 255, 255, 0.1);
  }
  50% {
    box-shadow: 0 0px 0px 4px rgba(110, 255, 0, 0.6);
  }

  100% {
    box-shadow: 0 0px 0px 4px rgba(255, 255, 255, 0.1);
  }
}

@keyframes success {
  0% {
    box-shadow: 0 0px 0px 4px rgba(255, 255, 255, 0.1);
  }
  50% {
    box-shadow: 0 0px 0px 4px rgba(110, 255, 0, 0.6);
  }

  100% {
    box-shadow: 0 0px 0px 4px rgba(255, 255, 255, 0.1);
  }
}

@-webkit-keyframes failure {
  0% {
    box-shadow: 0 0px 0px 4px rgba(255, 255, 255, 0.1);
  }
  50% {
    box-shadow: 0 0px 0px 4px rgba(255, 0, 0, 0.6);
  }

  100% {
    box-shadow: 0 0px 0px 4px rgba(255, 255, 255, 0.1);
  }
}

@keyframes failure {
  0% {
    box-shadow: 0 0px 0px 4px rgba(255, 255, 255, 0.1);
  }
  50% {
    box-shadow: 0 0px 0px 4px rgba(255, 0, 0, 0.6);
  }

  100% {
    box-shadow: 0 0px 0px 4px rgba(255, 255, 255, 0.1);
  }
}

.score--box {
    display: flex;
    /* flex-direction: row; */
    
}

.score--box > h1 {
    font-family: 'Carter One', sans-serif;
    color: white;
    margin: 1em;
    letter-spacing: .1em;
    text-shadow: 2px 2px 1px #0066a2, -2px 2px 1px #0066a2, 2px -2px 1px #0066a2, -2px -2px 1px #0066a2, 0px 2px 1px #0066a2, 0px -2px 1px #0066a2, 0px 4px 1px #004a87, 2px 4px 1px #004a87, -2px 4px 1px #004a87;
}
.wordstack {
  font-family: sans-serif;
  text-align: center;
}

.words__word {
  display: inline-block;
  color: #fff;
  padding: 20px 30px;
  border: 2px solid rgb(218, 165, 32);
  margin: 10px 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 3em;
  text-shadow: none;
}

.words__word--sel {
  color: turquoise;
}

.words__word-enter {
  opacity: 0;
}
.words__word-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.words__word-exit {
  opacity: 1;
}
.words__word-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.words__word:first-child {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  font-weight: 500;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
}

.words__input {
  margin-top: 20px;
}

.words--label {
  margin-right: 10px;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
}

.words--keys {
  border: none;
  cursor: pointer;
  transition: box-shadow 0.2s;
  text-transform: uppercase;
  padding: 1em;
  width: 600px;
  text-align: center;
  font-size: 2em;
  border-radius: 20px;
  background-color: #020144;
  box-shadow: 0 0px 0px 4px rgba(255, 255, 255, 0.1);
  color: azure;
}

.words--keys:focus {
  outline: none;
  /* box-shadow: 0 20px 15px rgba(255, 255, 255, 0.2); */
}

.words--keys--correct {
  -webkit-animation-name: correctWord;
          animation-name: correctWord;
  -webkit-animation-duration: 400ms;
          animation-duration: 400ms;
}

.words--keys--wrong {
  -webkit-animation-name: wrongWord;
          animation-name: wrongWord;
  -webkit-animation-duration: 400ms;
          animation-duration: 400ms;
}

@-webkit-keyframes correctWord {
  0% {
    box-shadow: 0 0px 0px 4px rgba(255, 255, 255, 0.1);
  }
  50% {
    box-shadow: 0 0px 0px 4px rgba(110, 255, 0, 0.6);
  }

  100% {
    box-shadow: 0 0px 0px 4px rgba(255, 255, 255, 0.1);
  }
}

@keyframes correctWord {
  0% {
    box-shadow: 0 0px 0px 4px rgba(255, 255, 255, 0.1);
  }
  50% {
    box-shadow: 0 0px 0px 4px rgba(110, 255, 0, 0.6);
  }

  100% {
    box-shadow: 0 0px 0px 4px rgba(255, 255, 255, 0.1);
  }
}

@-webkit-keyframes wrongWord {
  0% {
    box-shadow: 0 0px 0px 4px rgba(255, 255, 255, 0.1);
  }
  50% {
    box-shadow: 0 0px 0px 4px rgba(255, 0, 0, 0.6);
  }

  100% {
    box-shadow: 0 0px 0px 4px rgba(255, 255, 255, 0.1);
  }
}

@keyframes wrongWord {
  0% {
    box-shadow: 0 0px 0px 4px rgba(255, 255, 255, 0.1);
  }
  50% {
    box-shadow: 0 0px 0px 4px rgba(255, 0, 0, 0.6);
  }

  100% {
    box-shadow: 0 0px 0px 4px rgba(255, 255, 255, 0.1);
  }
}

.instructions-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #0066a2;
    border: 10px solid rgba(218, 165, 32);;
    border-radius: 25px;
    padding-bottom: 1em;
}

.instructions-header { 
    background: #004a87;
    text-align: center;
    width: 450px;
    padding: 1em;
}

.instructions-list {
    font-size: 1rem;
    width: 450px;
    line-height: 2em;
}

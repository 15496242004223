.instructions-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #0066a2;
    border: 10px solid rgba(218, 165, 32);;
    border-radius: 25px;
    padding-bottom: 1em;
}

.instructions-header { 
    background: #004a87;
    text-align: center;
    width: 450px;
    padding: 1em;
}

.instructions-list {
    font-size: 1rem;
    width: 450px;
    line-height: 2em;
}